<template>
  <div
      class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing bg-white"
  >
    <h4 class="mb-5 ms-3 mb-sm-0 fw-bold text-dark">Listado de tarifas</h4>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb mb-0 list-unstyled ps-0">
        <li class="breadcrumb-item active text-dark" aria-current="page">
          Tarifas
        </li>
      </ol>
    </nav>
  </div>
  <PriceList></PriceList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import PriceList from "@/components/Price/PriceList.vue";

export default {
  name: "Estados",
  components: {BreadCrumb, PriceList}
}
</script>

<style scoped>

</style>
