<template>
  <td @dblclick="startEdit" class="d-flex justify-content-start align-items-center">
    <button v-if="!editing" data-bs-toggle="tooltip"
            style="padding: 0.8rem"
            data-bs-placement="top" title="Editar tarifa" data-container="body"
            data-animation="true" class="btn text-info" @click="startEdit">
      <i class="flaticon-pen d-flex justify-content-center align-items-center"></i></button>
    <button v-else data-bs-toggle="tooltip"
            style="padding: 0.8rem"
            data-bs-placement="top" title="Guardar tarifa" data-container="body"
            data-animation="true" class="btn text-success" @click="endEdit">
      <i class="flaticon-diskette d-flex justify-content-center align-items-center"></i></button>
    <span v-if="!editing">{{ transfMount(value) }}</span>
    <input type="number" v-else v-model="newValue" @blur="endEdit" @keydown.enter="endEdit">
  </td>
</template>

<script>
import { ref, computed } from 'vue';
import {transfMount} from "@/util";

export default {
  methods: {transfMount},
  props: ['value'],
  emits: ['update:value'],
  setup(props, { emit }) {
    const editing = ref(false);
    const newValue = ref(props.value);

    const startEdit = () => {
      editing.value = true;
      newValue.value = props.value;
      //Evitar que se propaguen los eventos:
      window.addEventListener('dblclick',stopEventPropagation,{once:true});
    };

    const endEdit = () => {
      editing.value = false;
      emit('update:value', newValue.value);
      window.removeEventListener('dblclick',stopEventPropagation);
    };
    const stopEventPropagation = (e) => {
      e.stopPropagation();
    };

    return { editing, startEdit, endEdit, newValue };
  }
};
</script>