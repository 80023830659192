<template>
  <BreadCrumb PageTitle="Listado de contratos desde la App" Subtitle="Contratos"></BreadCrumb>
  <ContratosAppList></ContratosAppList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import MarcaList from "@/components/Nomencladores/MarcaList.vue";
import ContratosAppList from "@/components/Contratos/ContratosAppList.vue";

export default {
  name: "Clientes",
  components: {BreadCrumb, MarcaList, ContratosAppList}
}
</script>

<style scoped>

</style>
