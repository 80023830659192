<template>
  <BreadCrumb PageTitle="Listado de direcciones ip autorizadas" Subtitle="Seguridad"></BreadCrumb>
  <IpAuthList></IpAuthList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import IpAuthList from "@/components/Seguridad/IpAuthList.vue"

export default {
  name: "Usuarios",
  components: {BreadCrumb, IpAuthList}
}
</script>

<style scoped>

</style>
