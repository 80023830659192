<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative" @submit.prevent="loadData">
          <input
              type="text" v-model="search"
              @keyup.enter="loadData"
              @change="loadData"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar tarifa"
          />
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="Nuevo"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Nueva tarifa
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Categor&iacute;a
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Sucursal
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Procedencia
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Temporada
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Tarifa
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in items" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.categoria.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.sucursal.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.origen.nombre }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.isHigthSeason ? "Temporada Alta" : "Temporada Baja" }}
            </td>
            <EditableCell :value="item.tarifa" @update:value="updateTarifa(item, $event)"/>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button v-if="item.isActive" data-bs-toggle="tooltip"
                        style="padding: 0.8rem"
                        data-bs-placement="top" title="Desactivar tarifa" data-container="body"
                        data-animation="true" class="btn text-success" @click="Desactivar(item.id)">
                  <i class="flaticon-ban d-flex justify-content-center align-items-center"></i></button>
                <button v-else data-bs-toggle="tooltip" data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Activar tarifa" data-container="body" data-animation="true"
                        class="btn text-warning" @click="Activar(item.id)">
                  <i class="flaticon-check-mark-1 d-flex justify-content-center align-items-center"></i></button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing pt-2 pb-2">
    <div class="row">
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">

      </div>
      <div class="col-lg-6 d-flex align-items-center justify-content-center text-dark">
        <p class="me-3" style="margin-bottom: 0px">Elementos por p&aacute;gina</p>
        <select class="form-control" style="width: 50px; height: 40px; padding: 5px" v-model="cant">
          <option :value=10>10</option>
          <option :value=50>50</option>
          <option :value=100>100</option>
        </select>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
        <button class="btn btn-dark me-3" style="height: 40px" @click="prevPage" :disabled="hasPreviousPage===false"><i
            class="flaticon-chevron-1 "></i></button>
        <button class="btn btn-dark" style="height: 40px" @click="nextPage" :disabled="hasNextPage===false"><i
            class="flaticon-chevron"></i></button>
      </div>
    </div>
  </div>
  <div class="Nuevo text-dark">
    <div v-if="Nuevo_Modal" :class="['modal', { 'show': Nuevo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Nueva tarifa
            <i @click="Close_nuevo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Categor&iacute;a</label>
            <select :class="price.categoriaId===0?'is-invalid':''" v-model="price.categoriaId"
                    class="form-select shadow-none rounded-0 text-black">
              <option value="0" disabled>Seleccione la categor&iacute;a</option>
              <option v-for="item in categories" :value="item.id" :key="item.id">{{ item.name }}</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Sucursal</label>
            <select :class="price.sucursalId===0?'is-invalid':''" v-model="price.sucursalId"
                    class="form-select shadow-none rounded-0 text-black">
              <option value="0" disabled>Seleccione la sucursal</option>
              <option v-for="item in branchOffices" :value="item.id" :key="item.id">{{ item.name }}</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Procedencia</label>
            <select :class="price.origenVentaId===0?'is-invalid':''" v-model="price.origenVentaId"
                    class="form-select shadow-none rounded-0 text-black">
              <option value="0" disabled>Seleccione la procedencia</option>
              <option v-for="item in origens" :value="item.id" :key="item.id">{{ item.nombre }}</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Temporada</label>
            <select v-model="price.temporadaId"
                    class="form-select shadow-none rounded-0 text-black">
              <option :value="false">Temporada Baja</option>
              <option :value="true">Temporada Alta</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Tarifa</label>
            <input :class="price.precio===0?'is-invalid':''" v-model="price.precio"
                   class="form-control shadow-none rounded-0 text-black" type="number">
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vacíos
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_nuevo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Crear" class="btn btn-danger d-block w-100" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Actualizar text-dark">
    <div v-if="Actualizar_Modal" :class="['modal', { 'show': Actualizar_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Actualizar tarifa
            <i @click="Close_update" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Categor&iacute;a</label>
            <select disabled :class="price.categoriaId===0?'is-invalid':''" v-model="price.categoriaId"
                    class="form-select shadow-none rounded-0 text-black">
              <option value="0" disabled>Seleccione la categor&iacute;a</option>
              <option v-for="item in categories" :value="item.id" :key="item.id">{{ item.name }}</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Sucursal</label>
            <select disabled :class="price.sucursalId===0?'is-invalid':''" v-model="price.sucursalId"
                    class="form-select shadow-none rounded-0 text-black">
              <option value="0" disabled>Seleccione la sucursal</option>
              <option v-for="item in branchOffices" :value="item.id" :key="item.id">{{ item.name }}</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Procedencia</label>
            <select disabled :class="price.origenVentaId===0?'is-invalid':''" v-model="price.origenVentaId"
                    class="form-select shadow-none rounded-0 text-black">
              <option value="0" disabled>Seleccione la procedencia</option>
              <option v-for="item in origens" :value="item.id" :key="item.id">{{ item.nombre }}</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Temporada</label>
            <select disabled v-model="price.temporadaId"
                    class="form-select shadow-none rounded-0 text-black">
              <option :value="false">Temporada Baja</option>
              <option :value="true">Temporada Alta</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Tarifa</label>
            <input :class="price.precio===0?'is-invalid':''" v-model="price.precio"
                   class="form-control shadow-none rounded-0 text-black" type="number">
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vacíos
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_update" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Editar" class="btn btn-danger d-block w-100" type="button">Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {decodeBase64, transfMount} from "@/util";
import {
  getPrices,
  getOrigens,
  getBranchOfficeForContract,
  createPrice,
  searchCategories,
  updatePrice,
  desactivatePrice,
  activatePrice,
  getNextPrices,
  getPrevPrices
} from "@/actions";
import EditableCell from "@/components/EditableCell.vue";

export default {
  name: "PriceList",
  components: {EditableCell},
  data() {
    return {
      editing: false,
      newValue: 0,
      origens: [],
      categories: [],
      branchOffices: [],
      price: {
        id: 0,
        categoriaId: 0,
        origenVentaId: 0,
        precio: 0,
        sucursalId: 0,
        temporadaId: false
      },
      search: "",
      loading: true,
      Nuevo_Modal: false,
      Actualizar_Modal: false,
      error_vacios: false,

      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      lastStartCursor: "",
      endCursor: "",
      lastEndCursor: "",
      cant: 10,
      items: [],
    }
  },
  mounted() {
    this.loadData(true);
    this.loadOrigen()
    this.loadCategories()
    this.loadBranchOffices()
  },
  methods: {
    startEdit(item) {
      this.price.categoriaId = item.categoria.id
      this.price.origenVentaId = item.origen.id
      this.price.precio = item.tarifa
      this.price.id = decodeBase64(item.id)
      this.price.sucursalId = decodeBase64(item.sucursal.id)
      this.price.temporadaId = item.isHigthSeason
      this.editing = true;
    },
    transfMount,
    decodeBase64,
    Nuevo() {
      this.Nuevo_Modal = true
    },
    Close_nuevo() {
      this.price.categoriaId = 0
      this.price.origenVentaId = 0
      this.price.precio = 0
      this.price.sucursalId = 0
      this.price.temporadaId = false
      this.Nuevo_Modal = false
    },
    Close_update() {
      this.price.categoriaId = 0
      this.price.origenVentaId = 0
      this.price.precio = 0
      this.price.sucursalId = 0
      this.price.temporadaId = false
      this.editing = false
    },
    Crear() {
      let validacion = true
      if (this.categoriaId === 0) {
        this.error_vacios = true
        validacion = false
      }
      if (this.origenVentaId === 0) {
        this.error_vacios = true
        validacion = false
      }
      if (this.precio === 0) {
        this.error_vacios = true
        validacion = false
      }
      if (this.sucursalId === 0) {
        this.error_vacios = true
        validacion = false
      }
      if (validacion) {
        createPrice({
          categoriaId: decodeBase64(this.price.categoriaId),
          origenVentaId: decodeBase64(this.price.origenVentaId),
          precio: this.price.precio,
          sucursalId: decodeBase64(this.price.sucursalId),
          temporadaId: this.price.temporadaId
        }).then(resp => {
          if (resp.data.data.createPrice.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Operación realizada con éxito"
            });
            this.Close_nuevo()
            this.loadData();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: resp.data.data.createPrice.error
            });
          }
        })
      } else {

      }
    },
    Editar() {
      console.log("Se ejecuta esto")
      let validacion = true
      if (this.categoriaId === 0) {
        this.error_vacios = true
        validacion = false
      }
      if (this.origenVentaId === 0) {
        this.error_vacios = true
        validacion = false
      }
      if (this.precio === 0) {
        this.error_vacios = true
        validacion = false
      }
      if (this.sucursalId === 0) {
        this.error_vacios = true
        validacion = false
      }
      if (this.temporadaId === 0) {
        this.error_vacios = true
        validacion = false
      }
      if (validacion) {
        updatePrice({
          id: this.price.id,
          precio: this.price.precio
        }).then(resp => {
          if (resp.data.data.updatePrice.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Operación realizada con éxito"
            });
            this.Close_update()
            this.loadData();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Error al actualizar la tarifa"
            });
          }
        })
      } else {

      }
    },
    Ciudades(estado) {
      this.$store.state.estado = estado;
      this.$router.push({
        name: 'Ciudades'
      });
    },
    Activar(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "La tarifa volverá a estar disponible",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, activar!"
      }).then(async (result) => {
        if (result.isDenied) {
          activatePrice(id).then(response => {
            if (response.data.data.activatePrice.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
              this.loadData();
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al activar la ciudad"
              });
            }
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error
            });
          })
        }
      })
    },
    Desactivar(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "La ciudad no estará disponible",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, desactivar!"
      }).then(async (result) => {
        if (result.isDenied) {
          desactivatePrice(id).then(response => {
            if (response.data.data.desactivatePrice.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
              this.loadData();
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al desactivar la ciudad"
              });
            }
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error
            });
          })
        }
      })
    },
    loadOrigen() {
      getOrigens().then(resp => {
        this.origens = resp.data.data.procedenciaByName
      })
    },
    loadCategories() {
      searchCategories({
        name: "",
        before: "",
        after: "",
        first: 100
      }).then(resp => {
        this.categories = resp.data.data.searchCategories.edges.map(value => {
          return value.node;
        });
      }).catch(err => {

      }).finally(() => {
        this.loading = false;
      });
    },
    loadBranchOffices() {
      getBranchOfficeForContract().then(response => {
        this.branchOffices = response.data.data.sucursalesApp
      }).catch(err => console.log(err));
    },

    loadData(init = false) {
      this.cargando = true
      let next = "";
      let before = "";
      if (init === false) {
        before = this.lastEndCursor;
        next = this.lastStartCursor;
      }
      getPrices({
        name: this.search,
        before: before,
        after: next,
        first: this.cant
      }).then(resp => {
        if (init === true) {
          this.hasNextPage = resp.data.data.allPrices.pageInfo.hasNextPage;
          this.hasPreviousPage = resp.data.data.allPrices.pageInfo.hasPreviousPage;
        }
        this.startCursor = resp.data.data.allPrices.pageInfo.startCursor;
        this.endCursor = resp.data.data.allPrices.pageInfo.endCursor;
        this.items = resp.data.data.allPrices.edges.map(value => {
          return value.node;
        });
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.loading = false;
        this.cargando = false;
      });
    },
    nextPage() {
      this.cargando = true;
      getNextPrices({
        name: this.search,
        after: this.endCursor,
        first: this.cant
      }).then(resp => {
        this.lastEndCursor = "";
        this.lastStartCursor = this.endCursor;
        this.hasNextPage = resp.data.data.allPrices.pageInfo.hasNextPage;
        this.hasPreviousPage = true;
        this.startCursor = resp.data.data.allPrices.pageInfo.startCursor;
        this.endCursor = resp.data.data.allPrices.pageInfo.endCursor;
        this.items = resp.data.data.allPrices.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.cargando = false;
      });
    },
    prevPage() {
      this.cargando = true;
      getPrevPrices({
        name: this.search,
        before: this.startCursor,
        first: this.cant
      }).then(resp => {
        this.lastEndCursor = this.startCursor;
        this.lastStartCursor = "";
        this.hasNextPage = true;
        this.hasPreviousPage = resp.data.data.allPrices.pageInfo.hasPreviousPage;
        this.startCursor = resp.data.data.allPrices.pageInfo.startCursor;
        this.endCursor = resp.data.data.allPrices.pageInfo.endCursor;
        this.items = resp.data.data.allPrices.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.cargando = false;
      });
    },

    updateTarifa(item, newTarifa) {
      console.log("se ejecuta este metodo")
      this.price.categoriaId = item.categoria.id
      this.price.origenVentaId = item.origen.id
      this.price.precio = newTarifa
      this.price.id = decodeBase64(item.id)
      this.price.sucursalId = decodeBase64(item.sucursal.id)
      this.price.temporadaId = item.isHigthSeason
      this.Editar()
    }
  },
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 50%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
