<template>
  <div class="Cargando">
    <div v-show="loading || cargando" :class="['modal_cargando', { 'show': loading || cargando }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative">
          <input
              type="text" v-model="search"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar vehículo"
          />
          <button class="bg-transparent text-primary transition p-0 border-0" @click="loadDatas">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center">

      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="Nuevo"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Nuevo vehículo
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              N&uacute;mero econ&oacute;mico
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Categor&iacute;a
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Marca
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Modelo
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Sucursal
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Ciudad
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Kilometraje de &uacute;ltimo servicio
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Kilometraje actual
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Km para p&oacute;ximo servicio
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in items" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.noEconomico }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.categoria.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.modelo.marca.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.modelo.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.sucursal ? item.sucursal.name : "" }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.sucursal ? item.sucursal.ciudad.name : "" }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.kmService }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.kilometraje }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              <span class="badge rounded-pill fs-13 w-100"
                    :class="getClassKm(item)">{{ parseFloat(item.kmControl) - parseFloat(item.kilometraje) }}</span>
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button v-if="item.isActive" data-bs-toggle="tooltip"
                        style="padding: 0.8rem"
                        data-bs-placement="top" title="Desactivar vehículo" data-container="body"
                        data-animation="true" class="btn text-success" @click="Desactivar(item.id)">
                  <i class="flaticon-ban d-flex justify-content-center align-items-center"></i></button>
                <button v-else data-bs-toggle="tooltip" data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Activar vehículo" data-container="body" data-animation="true"
                        class="btn text-warning" @click="Activar(item.id)">
                  <i class="flaticon-check-mark-1 d-flex justify-content-center align-items-center"></i></button>
                <button data-bs-toggle="tooltip" @click="Actualizar(item.id)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Editar vehículo" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i></button>
                <button data-bs-toggle="tooltip" @click="Details(item.id)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Ver detalles del vehículo" data-container="body" data-animation="true"
                        class="btn text-secondary">
                  <i class="flaticon-eye d-flex justify-content-center align-items-center "></i></button>
                <button data-bs-toggle="tooltip" @click="OpenKmInfo(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Gestionar información de servicio" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-date  d-flex justify-content-center align-items-center "></i></button>
                <button data-bs-toggle="tooltip" @click="SendMaintenece(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Enviar auto a mantenimiento" data-container="body" data-animation="true"
                        class="btn text-primary">
                  <i class="flaticon-settings-1  d-flex justify-content-center align-items-center "></i></button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing pt-2 pb-2">
    <div class="row">
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">

      </div>
      <div class="col-lg-6 d-flex align-items-center justify-content-center text-dark">
        <p class="me-3" style="margin-bottom: 0px">Elementos por p&aacute;gina</p>
        <select class="form-control" style="width: 50px; height: 40px; padding: 5px" v-model="cant">
          <option :value=10>10</option>
          <option :value=50>50</option>
          <option :value=100>100</option>
        </select>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
        <button class="btn btn-dark me-3" style="height: 40px" @click="prevPage" :disabled="hasPreviousPage===false"><i
            class="flaticon-chevron-1 "></i></button>
        <button class="btn btn-dark" style="height: 40px" @click="nextPage" :disabled="hasNextPage===false"><i
            class="flaticon-chevron"></i></button>
      </div>
    </div>
  </div>
  <div class="Nuevo text-dark">
    <div v-if="Nuevo_Modal" :class="['modal', { 'show': Nuevo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Nuevo veh&iacute;culo
            <i @click="Close_nuevo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Categoria">
            <label class="form-label ms-1">Categor&iacute;a</label>
            <select :class="categoria===0?'is-invalid':''"
                    v-model="categoria"
                    class="form-control shadow-none text-black rounded-0 border-0">
              <option :value="0">Seleccione una categor&iacute;a</option>
              <option v-for="item in categoriasByName.filter(e=>e.isActive)" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
            <div v-if="categoria===0" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Año">
            <label class="form-label ms-1">Año del veh&iacute;culo</label>
            <input :class="anno===''?'is-invalid':''" v-model="anno"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="anno===''" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 sucursal">
            <label class="form-label ms-1">Sucursal</label>
            <select :class="sucursal===0?'is-invalid':''"
                    v-model="sucursal"
                    class="form-control shadow-none text-black rounded-0 border-0">
              <option :value="0">Seleccione una sucursal</option>
              <option v-for="item in sucursales" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
            <div v-if="sucursal===0" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 No_econ">
            <label class="form-label ms-1">N&uacute;mero econ&oacute;mico</label>
            <input :class="noEconomico===''?'is-invalid':''" v-model="noEconomico"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="noEconomico===''" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Marca">
            <label class="form-label ms-1">Marca</label>
            <select :class="marca===0?'is-invalid':''" autofocus v-model="marca"
                    class="form-control shadow-none rounded-0 text-black">
              <option value="0" disabled>Seleccione una marca</option>
              <option v-for="item in marcasByName.filter(e=>e.isActive)" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
            <div v-if="marca===0" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Modelo">
            <label class="form-label ms-1">Modelo</label>
            <select :class="modelo===0?'is-invalid':''" autofocus v-model="modelo" :disabled="marca===0"
                    class="form-control shadow-none rounded-0 text-black">
              <option value="0" disabled>Seleccione un modelo</option>
              <option
                  v-for="item in marca===0?[]:marcasByName.find(e=>e.id==marca).modeloSet.edges.map(e=>e.node).filter(e=>e.isActive)"
                  :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
            <div v-if="modelo===0" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 No_serie">
            <label class="form-label ms-1">N&uacute;mero de serie</label>
            <input :class="no_serie===''?'is-invalid':''" v-model="no_serie"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="no_serie===''" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Placa">
            <label class="form-label ms-1">Placa</label>
            <input :class="no_placa==='' || no_placa===null?'is-invalid':''" v-model="no_placa"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="no_placa==='' || no_placa===null" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Procedencia">
            <label class="form-label ms-1">Procedencia</label>
            <input :class="procedencia===''?'is-invalid':''" v-model="procedencia"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="procedencia===''" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Seguro">
            <label class="form-label ms-1">Seguro</label>
            <input :class="seguro===''?'is-invalid':''" v-model="seguro"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="seguro===''" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Valor">
            <label class="form-label ms-1">Valor</label>
            <input :class="valor<=0?'is-invalid':''" v-model="valor" type="number" min="0"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="valor<=0" class="invalid-feedback">
              Campo no puede estar vac&iacute;o y tiene que ser mayor que 0
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Color">
            <label class="form-label ms-1">Color</label>
            <input :class="color===''?'is-invalid':''" v-model="color"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="color===''" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Fecha_circulacion">
            <label class="form-label ms-1">Fecha de circulaci&oacute;n</label>
            <input :class="fechaCirculacion===null?'is-invalid':''" v-model="fechaCirculacion" type="date"
                   :min="dateVencMin" class="form-control shadow-none rounded-0 text-black">
            <div v-if="fechaCirculacion===null" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Fecha_gps">
            <label class="form-label ms-1">Fecha de GPS</label>
            <input :class="fechaGps===null?'is-invalid':''" v-model="fechaGps" type="date" :min="dateVencMin"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="fechaGps===null" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 fecha_seguro">
            <label class="form-label ms-1">Fecha de seguro</label>
            <input :class="fechaSeguro===null?'is-invalid':''" v-model="fechaSeguro" type="date" :min="dateVencMin"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="fechaSeguro===null" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_nuevo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button :disabled="validateData" @click="Crear" class="btn btn-danger d-block w-100" type="button">Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Actualizar text-dark">
    <div v-if="Actualizar_Modal" :class="['modal', { 'show': Actualizar_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Actualizar veh&iacute;culo
            <i @click="Close_update" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Categoria">
            <label class="form-label ms-1">Categor&iacute;a</label>
            <select :class="categoria===0?'is-invalid':''"
                    v-model="categoria"
                    class="form-control shadow-none text-black rounded-0 border-0">
              <option :value="0">Seleccione una categor&iacute;a</option>
              <option v-for="item in categoriasByName.filter(e=>e.isActive)" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
            <div v-if="categoria===0" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Año">
            <label class="form-label ms-1">Año del veh&iacute;culo</label>
            <input :class="anno===''?'is-invalid':''" v-model="anno"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="anno===''" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 sucursal">
            <label class="form-label ms-1">Sucursal</label>
            <select :class="sucursal===0?'is-invalid':''"
                    v-model="sucursal"
                    class="form-control shadow-none text-black rounded-0 border-0">
              <option :value="0">Seleccione una sucursal</option>
              <option v-for="item in sucursales" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
            <div v-if="sucursal===0" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 No_econ">
            <label class="form-label ms-1">N&uacute;mero econ&oacute;mico</label>
            <input :class="noEconomico===''?'is-invalid':''" v-model="noEconomico"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="noEconomico===''" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Marca">
            <label class="form-label ms-1">Marca</label>
            <select :class="marca===0?'is-invalid':''" autofocus v-model="marca"
                    class="form-control shadow-none rounded-0 text-black">
              <option value="0" disabled>Seleccione una marca</option>
              <option v-for="item in marcasByName.filter(e=>e.isActive)" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
            <div v-if="marca===0" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Modelo">
            <label class="form-label ms-1">Modelo</label>
            <select :class="modelo===0?'is-invalid':''" autofocus v-model="modelo" :disabled="marca===0"
                    class="form-control shadow-none rounded-0 text-black">
              <option value="0" disabled>Seleccione un modelo</option>
              <option
                  v-for="item in marca===0?[]:marcasByName.find(e=>e.id==marca).modeloSet.edges.map(e=>e.node).filter(e=>e.isActive)"
                  :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
            <div v-if="modelo===0" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 No_serie">
            <label class="form-label ms-1">N&uacute;mero de serie</label>
            <input :class="no_serie===''?'is-invalid':''" v-model="no_serie"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="no_serie===''" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Placa">
            <label class="form-label ms-1">Placa</label>
            <input :class="no_placa==='' || no_placa===null?'is-invalid':''" v-model="no_placa"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="no_placa==='' || no_placa===null" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Procedencia">
            <label class="form-label ms-1">Procedencia</label>
            <input :class="procedencia===''?'is-invalid':''" v-model="procedencia"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="procedencia===''" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Seguro">
            <label class="form-label ms-1">Seguro</label>
            <input :class="seguro===''?'is-invalid':''" v-model="seguro"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="seguro===''" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Valor">
            <label class="form-label ms-1">Valor</label>
            <input :class="valor<=0?'is-invalid':''" v-model="valor" type="number" min="0"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="valor<=0" class="invalid-feedback">
              Campo no puede estar vac&iacute;o y tiene que ser mayor que 0
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Color">
            <label class="form-label ms-1">Color</label>
            <input :class="color===''?'is-invalid':''" v-model="color"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="color===''" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Fecha_circulacion">
            <label class="form-label ms-1">Fecha de circulaci&oacute;n</label>
            <input :class="fechaCirculacion===null?'is-invalid':''" v-model="fechaCirculacion" type="date"
                   :min="dateVencMin" class="form-control shadow-none rounded-0 text-black">
            <div v-if="fechaCirculacion===null" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Fecha_gps">
            <label class="form-label ms-1">Fecha de GPS</label>
            <input :class="fechaGps===null?'is-invalid':''" v-model="fechaGps" type="date" :min="dateVencMin"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="fechaGps===null" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 fecha_seguro">
            <label class="form-label ms-1">Fecha de seguro</label>
            <input :class="fechaSeguro===null?'is-invalid':''" v-model="fechaSeguro" type="date" :min="dateVencMin"
                   class="form-control shadow-none rounded-0 text-black">
            <div v-if="fechaSeguro===null" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_update" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button :disabled="validateData" @click="Editar" class="btn btn-danger d-block w-100" type="button">
              Actualizar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="KmInfo text-dark">
    <div v-if="KmInfoModal" :class="['modal', { 'show': KmInfoModal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Informac&iacute;on de servicio del veh&iacute;culo
            <i @click="CloseKmInfo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Kilometraje actual</label>
            <input v-model="kilometraje" class="form-control shadow-none rounded-0 text-black" type="number">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Kilometraje para pr&oacute;ximo servicio</label>
            <input v-model="km_control" class="form-control shadow-none rounded-0 text-black" type="number">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Kilometraje de &uacute;ltimo servicio</label>
            <input v-model="km_service" class="form-control shadow-none rounded-0 text-black" type="number">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Kil&oacute;metros para el pr&oacute;ximo servicio</label>
            <input readonly v-model="km_dif" class="form-control shadow-none rounded-0 text-black" type="number">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="CloseKmInfo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="SaveKmInfo" class="btn btn-danger d-block w-100" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Detalles text-dark">
    <div v-if="Details_Modal" :class="['modal', { 'show': Details_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Detalles del veh&iacute;culo
            <i @click="Close_details" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 No_econ">
            <label class="form-label ms-1"><strong>N&uacute;mero econ&oacute;mico:</strong> {{
                item.noEconomico
              }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Marca">
            <label class="form-label ms-1"><strong>Marca:</strong> {{ item.modelo.marca.name }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Modelo">
            <label class="form-label ms-1"><strong>Modelo:</strong> {{ item.modelo.name }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 No_serie">
            <label class="form-label ms-1"><strong>N&uacute;mero de serie:</strong> {{ item.noSerie }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Placa">
            <label class="form-label ms-1"><strong>Placa:</strong> {{ item.noPlaca }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Procedencia">
            <label class="form-label ms-1"><strong>Procedencia:</strong> {{ item.procedencia }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Seguro">
            <label class="form-label ms-1"><strong>Seguro:</strong> {{ item.seguro }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Valor">
            <label class="form-label ms-1"><strong>Valor:</strong> $ {{ item.valor }} MXN</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Color">
            <label class="form-label ms-1"><strong>Color:</strong> {{ item.color }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Fecha_circulacion">
            <label class="form-label ms-1"><strong>Fecha de circulaci&oacute;n:</strong> {{
                item.fechaCirculacion
              }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Fecha_gps">
            <label class="form-label ms-1"><strong>Fecha de GPS:</strong> {{ item.fechaGps }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 fecha_seguro">
            <label class="form-label ms-1"><strong>Fecha de seguro:</strong> {{ item.fechaSeguro }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Sucursal">
            <label class="form-label ms-1"><strong>Sucursal:</strong> {{
                item.sucursal ? item.sucursal.name : ""
              }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Ciudad">
            <label class="form-label ms-1"><strong>Ciudad:</strong> {{
                item.sucursal ? item.sucursal.ciudad ? item.sucursal.ciudad.name : "" : ""
              }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Close_details" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import {
  searchCar,
  searchNextCar,
  searchPrevCar,
  getMarcaModel,
  getAutoById,
  createAuto,
  updateAuto,
  searchCategories,
  enableAuto,
  disableAuto, getBranchOfficeForContract, editKmInfo
} from "@/actions";
import {decodeBase64} from "@/util";

export default {
  name: "AutoList",
  data() {
    return {
      KmInfoModal: false,
      kilometraje: 0,
      km_service: 0,
      km_dif: 0,
      km_control: 0,
      car_id: 0,

      vehiculosByName: [],
      categoriasByName: [],
      marcasByName: [],
      modelosByName: [],
      sucursales: [],
      sucursal: 0,
      search: "",
      Nuevo_Modal: false,
      Details_Modal: false,
      File_Modal: false,
      cargando: false,
      Actualizar_Modal: false,
      name: '',
      phone: "",
      id: 0,
      no_serie: '',
      categoria: 0,
      color: "",
      anno: "",
      fechaCirculacion: null,
      fechaGps: null,
      fechaSeguro: null,
      modelo: 0,
      marca: 0,
      noEconomico: "",
      no_placa: "",
      procedencia: "",
      seguro: "",
      valor: 0,
      error_vacios: false,
      error_circulacion: false,
      error_gps: false,
      error_seguro: false,
      item: null,

      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      lastStartCursor: "",
      endCursor: "",
      lastEndCursor: "",
      cant: 10,
      items: [],

      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      }),
    }
  },
  mounted() {
    this.loadDatas(true);
    this.loadDataExtra()
  },
  watch: {
    km_control() {
      this.km_dif = parseFloat(this.km_control) - parseFloat(this.kilometraje)
    },
    kilometraje() {
      this.km_dif = parseFloat(this.km_control) - parseFloat(this.kilometraje)
    },
    vehiculosByName() {
      this.loadDataExtra()
    },
    item() {
      this.cargando = false
    },
    items() {
      this.cargando = false;
    },
    cant() {
      this.loadDatas(true);
    },
  },
  methods: {
    SendMaintenece(item) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "El vehículo no estará disponible",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, enviar a mantenimiento!"
      }).then(async (result) => {
        if (result.isDenied) {
          this.$store.state.car_id = item.id
          this.$router.push({
            name: 'Maintenance'
          })
        }
      });
    },
    getClassKm(item) {
      if (parseFloat(item.kmControl) - parseFloat(item.kilometraje) > 3000) {
        return 'text-bg-success'
      } else if (parseFloat(item.kmControl) - parseFloat(item.kilometraje) <= 3000 && parseFloat(item.kmControl) - parseFloat(item.kilometraje) > 500) {
        return 'text-bg-warning'
      } else {
        return 'text-bg-danger'
      }
    },
    OpenKmInfo(item) {
      this.car_id = decodeBase64(item.id)
      this.kilometraje = item.kilometraje
      this.km_service = item.kmService
      this.km_control = item.kmControl
      this.km_dif = parseFloat(this.km_control) - parseFloat(this.kilometraje)
      this.KmInfoModal = true
    },
    CloseKmInfo() {
      this.kilometraje = 0
      this.kmService = 0
      this.kmControl = 0
      this.kmDif = 0
      this.KmInfoModal = false
    },
    SaveKmInfo() {
      editKmInfo({
        id: this.car_id,
        kilometraje: this.kilometraje,
        kmControl: this.km_control,
        kmService: this.km_service,
      }).then(response => {
        if (response.data.data.editKmInfo.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.CloseKmInfo()
          this.loadDatas()
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.data.editKmInfo.error
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      }).finally(() => {
        this.$store.state.Cargando = false;
      })
    },
    loadDataExtra() {
      getMarcaModel().then(resp => {
        this.marcasByName = resp.data.data.marcasByName;
      }).catch(err => console.log(err));
      searchCategories({
        name: "",
        before: "",
        after: "",
        first: 50
      }).then(resp => {
        this.categoriasByName = resp.data.data.searchCategories.edges.map(value => {
          return value.node;
        });
      }).catch(err => console.log(err));
      getBranchOfficeForContract().then(resp => {
        this.sucursales = resp.data.data.sucursalesApp
      }).catch(err => console.log(err));
    },
    Nuevo() {
      this.Nuevo_Modal = true
    },
    Actualizar(id) {
      this.cargando = true
      getAutoById(id).then(response => {
        this.item = response.data.data.searchAutoById;
        this.marca = this.item.modelo.marca.id;
        this.color = this.item.color;
        this.no_serie = this.item.noSerie;
        this.fechaCirculacion = this.item.fechaCirculacion;
        this.fechaGps = this.item.fechaGps;
        this.fechaSeguro = this.item.fechaSeguro;
        this.modelo = this.item.modelo.id;
        this.noEconomico = this.item.noEconomico;
        this.no_placa = this.item.noPlaca;
        this.procedencia = this.item.procedencia;
        this.seguro = this.item.seguro;
        this.valor = this.item.valor;
        this.id = this.item.id;
        this.categoria = this.item.categoria.id;
        this.sucursal = this.item.sucursal ? decodeBase64(this.item.sucursal.id) : 0;
        this.Actualizar_Modal = true;
      }).catch(err => console.log(err)).finally(() => {
        this.cargando = false;
      })
    },
    Details(id) {
      this.cargando = true;
      getAutoById(id).then(response => {
        this.item = response.data.data.searchAutoById;
        this.Details_Modal = true;
      }).catch(err => console.log(err)).finally(() => {
        this.cargando = false;
      })
    },
    Close_details() {
      this.item = null
      this.Details_Modal = false
    },
    Close_nuevo() {
      this.$store.state.Cargando = false
      this.id = 0;
      this.color = "";
      this.fechaCirculacion = null;
      this.fechaGps = null;
      this.fechaSeguro = null;
      this.modelo = 0;
      this.marca = 0;
      this.noEconomico = "";
      this.no_placa = "";
      this.no_serie = "";
      this.procedencia = "";
      this.seguro = "";
      this.categoria = 0;
      this.sucursal = 0;
      this.anno = "";
      this.valor = 0;
      this.Nuevo_Modal = false
    },
    Close_update() {
      this.$store.state.Cargando = false
      this.id = 0;
      this.color = "";
      this.fechaCirculacion = null;
      this.fechaGps = null;
      this.fechaSeguro = null;
      this.modelo = 0;
      this.marca = 0;
      this.noEconomico = "";
      this.no_placa = "";
      this.no_serie = "";
      this.procedencia = "";
      this.seguro = "";
      this.categoria = 0;
      this.sucursal = 0;
      this.anno = "";
      this.valor = 0;
      this.Actualizar_Modal = false
    },
    Crear() {
      if (this.color === "" || this.fechaCirculacion === null || this.fechaGps === null ||
          this.fechaSeguro === null || this.modelo === 0 || this.noEconomico === "" || this.no_serie === "" ||
          this.no_placa === "" || this.anno === "" || this.procedencia === "" || this.seguro === "" || this.valor === 0 || this.sucursal === 0) {
        this.error_vacios = true
        return false
      }
      this.error_vacios = false
      if (this.Difechas(this.fechaCirculacion, Date.now()) > 24) {
        this.error_circulacion = true
        return false
      }
      this.error_circulacion = false
      if (this.Difechas(this.fechaGps, Date.now()) > 24) {
        this.error_gps = true
        return false
      }
      this.error_gps = false
      if (this.Difechas(this.fechaSeguro, Date.now()) > 24) {
        this.error_seguro = true
        return false
      }
      this.error_seguro = false
      this.$store.state.Cargando = true;
      createAuto({
        categoria: decodeBase64(this.categoria),
        color: this.color,
        anno: this.anno,
        fechaCirculacion: this.fechaCirculacion,
        fechaGps: this.fechaGps,
        fechaSeguro: this.fechaSeguro,
        modelo: decodeBase64(this.modelo),
        noEconomico: this.noEconomico,
        noSerie: this.no_serie,
        noPlaca: this.no_placa,
        procedencia: this.procedencia,
        seguro: this.seguro,
        valor: this.valor,
        sucursal: this.sucursal
      }).then(response => {
        if (response.data.data.createVehiculo.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.Close_nuevo();
          this.loadDatas();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.data.createVehiculo.error
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      }).finally(() => {
        this.$store.state.Cargando = false;
      })
    },
    Difechas(fecha1, fecha2) {
      return moment(fecha2).diff(moment(fecha1), "hours");
    },
    Editar() {
      this.$store.state.Cargando = true
      updateAuto({
        categoria: decodeBase64(this.categoria),
        color: this.color,
        fechaCirculacion: this.fechaCirculacion,
        fechaGps: this.fechaGps,
        fechaSeguro: this.fechaSeguro,
        modelo: decodeBase64(this.modelo),
        noEconomico: this.noEconomico,
        noSerie: this.no_serie,
        noPlaca: this.no_placa,
        procedencia: this.procedencia,
        seguro: this.seguro,
        valor: this.valor,
        anno: this.anno,
        sucursal: this.sucursal,
        id: decodeBase64(this.id)
      }).then(response => {
        if (response.data.data.updateVehiculo.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.Close_update();
          this.loadDatas();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.data.updateVehiculo.error
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      }).finally(() => {
        this.$store.state.Cargando = false;
      })
    },
    Activar(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "El vehículo volverá a estar disponible",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, activar!"
      }).then(async (result) => {
        if (result.isDenied) {
          enableAuto({
            id: decodeBase64(id)
          }).then(response => {
            if (response.data.data.activeVehiculo.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
              this.loadDatas();
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al activar el vehículo"
              });
            }
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error
            });
          })
        }
      });
    },
    Desactivar(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "El vehículo no estará disponible",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, desactivar!"
      }).then(async (result) => {
        if (result.isDenied) {
          disableAuto({
            id: decodeBase64(id)
          }).then(response => {
            if (response.data.data.desactiveVehiculo.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
              this.loadDatas();
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al desactivar el vehículo"
              });
            }
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error
            });
          })
        }
      });
    },

    loadDatas(init = false) {
      this.cargando = true
      let next = "";
      let before = "";
      if (init === false) {
        before = this.lastEndCursor;
        next = this.lastStartCursor;
      }
      searchCar({
        name: this.search,
        before: before,
        after: next,
        first: this.cant
      }).then(resp => {
        if (init === true) {
          this.hasNextPage = resp.data.data.searchAutos.pageInfo.hasNextPage;
          this.hasPreviousPage = resp.data.data.searchAutos.pageInfo.hasPreviousPage;
        }
        this.startCursor = resp.data.data.searchAutos.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchAutos.pageInfo.endCursor;
        this.items = resp.data.data.searchAutos.edges.map(value => {
          return value.node;
        });
      }).catch(err => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
        this.cargando = false;
      });
    },
    nextPage() {
      this.cargando = true;
      searchNextCar({
        name: this.search,
        after: this.endCursor,
        first: this.cant
      }).then(resp => {
        this.lastEndCursor = "";
        this.lastStartCursor = this.endCursor;
        this.hasNextPage = resp.data.data.searchAutos.pageInfo.hasNextPage;
        this.hasPreviousPage = true;
        this.startCursor = resp.data.data.searchAutos.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchAutos.pageInfo.endCursor;
        this.items = resp.data.data.searchAutos.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.cargando = false;
      });
    },
    prevPage() {
      this.cargando = true;
      searchPrevCar({
        name: this.search,
        before: this.startCursor,
        first: this.cant
      }).then(resp => {
        this.lastEndCursor = this.startCursor;
        this.lastStartCursor = "";
        this.hasNextPage = true;
        this.hasPreviousPage = resp.data.data.searchAutos.pageInfo.hasPreviousPage;
        this.startCursor = resp.data.data.searchAutos.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchAutos.pageInfo.endCursor;
        this.items = resp.data.data.searchAutos.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.cargando = false;
      });
    },
  },
  computed: {
    loading() {
      return this.$apollo.loading;
    },
    dateVencMin() {
      const d = new Date();
      const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      return `${d.getFullYear()}-${month}-${d.getDate()}`;
    },
    validateData() {
      return this.categoria === 0 || this.noEconomico === '' || this.marca === 0 || this.modelo === 0 || this.no_serie === ''
          || this.no_placa === '' || this.no_placa === null || this.procedencia === '' || this.seguro === ''
          || this.valor <= 0 || this.color === '' || this.fechaCirculacion === null
          || this.fechaGps === null || this.fechaSeguro === null;
    }
  }
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 50%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
